import { forwardRef } from "react";
// import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
// import generatePicker from "antd/es/date-picker/generatePicker";
// import "antd/es/date-picker/style/index";
import { DatePicker as DatePickerAntd } from "antd";
import useFormatting from "hooks/useFormatting";

// const DayjsDatePicker = generatePicker(dayjsGenerateConfig);

const DatePicker = ({ innerRef, ...props }) => {
  const { formatDateText } = useFormatting();
  return <DatePickerAntd ref={innerRef} format={formatDateText()} {...props} />;
};

export default forwardRef((props, ref) => (
  <DatePicker innerRef={ref} {...props} />
));
