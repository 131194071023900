import { useEffect } from "react";
import { isTokenExist } from "utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import store from "store";

import {
  setApp,
  setConfig,
  setMenu,
  setOption,
  setLoadedInit,
} from "redux/configSlice";
import { setReference } from "redux/referenceSlice";
import { userSelector, loadedInitSelector } from "redux/selectors";
import { useInits } from "queries/inits";

// Digunakan untuk handle mengisi return endpoint dari init ke state redux
const useLoadInits = (skipCheckEndpoint = false) => {
  const dispatch = useDispatch();
  const tokenExist = isTokenExist();
  const user = useSelector(userSelector);
  const loadedInit = useSelector(loadedInitSelector);
  const checkEndpoint = skipCheckEndpoint ? true : !!store.get("app.endpoint");
  const enabled = tokenExist && !user.id && !loadedInit && checkEndpoint;
  const { data } = useInits({
    enabled,
  });

  // Set redux from inits
  useEffect(() => {
    if (data?.user && enabled) {
      const websiteId = parseInt(data.website_id, 10);
      store.set("app.settings.locale", data.user?.lang);
      dispatch(
        setConfig({
          locale: data.user?.lang,
          endpoint: data.endpoint,
          selectedCompany: { website_id: websiteId, endpoint: data.endpoint },
        })
      );
      dispatch(
        setApp({
          user: data.user,
          lang: data.lang,
          billing: data.billing,
          translation: data.translation,
          companies: data.companies || [],
          website_id: websiteId,
          setting: data.setting,
          dashboard: data.dashboard,
          gajihubStartDate: data?.gajihub_start_date,
          employeeCountAvailable: data?.employee_count_available || [],
          defaultMinEmployeeOrder: data?.default_min_employee_order || 0,
          maxCompanies: data?.max_companies || 0,
          showNps: data?.show_nps || 0,
        })
      );
      dispatch(setOption(data.option));
      dispatch(setMenu(data.user?.menus));
      dispatch(setReference(data.reference));
      dispatch(setLoadedInit(true));
    }
  }, [data, dispatch, enabled]);
};

export default useLoadInits;
