import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { isTokenExist } from "utils/cookies";
import useLoadInits from "hooks/useLoadInits";
import { useHrCompanieSelector } from "hooks/useSelector";
import store from "store";
import PublicLayout from "../Public";

const SelectCompanyLayout = () => {
  const navigate = useNavigate();
  const { companies } = useHrCompanieSelector();
  const skipCheckEndpoint = true;

  useEffect(() => {
    if (isTokenExist()) {
      if (store.get("app.endpoint") && companies.length > 0) {
        navigate(store.get("app.previousPathname") || "/dashboard", {
          replace: true,
        });
      }
    } else {
      navigate("/user/login", { replace: true });
    }
  }, [companies, navigate]);

  useLoadInits(skipCheckEndpoint);

  return (
    <PublicLayout className="min-h-screen bg-primary dark:bg-black" inverse>
      <Outlet />
    </PublicLayout>
  );
};

export default SelectCompanyLayout;
