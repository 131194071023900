import { useQuery, useMutation } from "react-query";

import { message } from "antd";
import * as api from "apis/cashReceipt";
import { useTranslation } from "react-i18next";

export const useCashReceiptBalance = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["cashReceiptBalance", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCashReceiptBalance(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useCashReceiptHistory = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["cashReceiptHistory", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCashReceiptHistory(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useCashReceiptPayment = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["cashReceiptPayment", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCashReceiptPayment(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useCashReceiptEmployee = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["cashReceiptEmployee", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCashReceiptEmployee(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useCashReceipt = ({ payload = {} }, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["cashReceipt", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCashReceipt(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useAddCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddCashReceiptPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addCashReceiptPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const usePaidCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.paidCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteCashReceiptPayment = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteCashReceiptPayment(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  upload import cash receipt
export const useUploadImportCashReceipt = () => {
  return useMutation((payload) => api.uploadImportCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query  execute import cash receipt
export const useExecuteImportCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query  upload import plafon cash receipt
export const useUploadImportPlafonCashReceipt = () => {
  return useMutation((payload) => api.uploadImportPlafonCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query  execute import plafon cash receipt
export const useExecuteImportPlafonCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportPlafonCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete attachment cash receipt
export const useDeleteAttachmentCashReceipt = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentCashReceipt(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
