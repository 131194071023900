import axios from "utils/axios";

export const getEmployeeCareers = (id) => {
  return axios.get(`/hr/careers/employees/${id}`);
};

export const getCareer = (id) => {
  return axios.get(`/hr/careers/${id}`);
};

export const getEffectiveDateCareer = (id) => {
  return axios.get(`/hr/careers/employees/${id}/new`);
};

export const addCareer = (data) => {
  return axios.post("/hr/careers/", data);
};

export const editCareer = (data) => {
  return axios.put(`/hr/careers/${data?.careerId}`, data);
};

export const deleteCareer = (data) => {
  return axios.delete(`/hr/careers/${data?.careerId}`);
};
