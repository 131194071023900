import axios from "utils/axios";
import { stringify } from "query-string";

export const getPayrollPayments = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/payrollPayments?${query}`);
};

export const getPayrollPayment = (id, params) => {
  const query = stringify(params);
  return axios.get(`/hr/payrollPayments/${id}?${query}`);
};

export const getPayrollPaymentHistory = (data) => {
  const query = stringify(data);
  return axios.get(
    `/hr/payrollPayments/${data?.id}/history/pagination?${query}`
  );
};

export const getPayrollPaymentPreview = (data) => {
  const query = stringify(data);
  return axios.get(`/hr/payrollPayments/${data?.id}/export/preview?${query}`);
};

export const getPayrollPaymentMethods = (id) => {
  return axios.get(`/hr/payrollPayments/${id}/paymentMethods/manual`);
};

export const getMultiplePayrollPaymentMethods = (id) => {
  return axios.get(`/hr/payrollPayments/${id}/paymentMethods/manual/allSlip`);
};

export const getReportBpjsSocialSecurities = (data) => {
  const query = stringify(data);
  return axios.get(`/hr/payrollPayments/report/bpjsSocialSecurities?${query}`);
};

export const getReportBpjsHealthcares = (data) => {
  const query = stringify(data);
  return axios.get(`/hr/payrollPayments/report/bpjsHealthcares?${query}`);
};

export const massPayrollPaymentsDownload = (id) => {
  return axios.get(`/hr/payrollPayments/${id}/multiple/export/zip`);
};

export const addPayrollPaymentNonFixedSalarySlip = (data) => {
  return axios.post("/hr/payrollPayments/nonFixedSalarySlips", data);
};

export const confirmPayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data.id}/paymentMethods/manual/${data.refBankId}/confirm`,
    data
  );
};

export const confirmMultiplePayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data.id}/paymentMethods/manual/allSlip/${data.refBankId}/confirm`,
    data
  );
};

export const splitPayrollPaymentSlip = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data?.id}/splitPayrollPaymentSlip`,
    data
  );
};

export const unsplitPayrollPaymentSlip = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data?.id}/unsplitPayrollPaymentSlip`,
    data
  );
};

export const editPayrollPayment = (data) => {
  return axios.put(`/hr/payrollPayments/${data.id}`, data);
};

export const editIncomeManualComponent = (data) => {
  return axios.put(`/hr/payrollPayments/${data?.id}/manual/income`, data);
};

export const editDeductionManualComponent = (data) => {
  return axios.put(`/hr/payrollPayments/${data?.id}/manual/deduction`, data);
};

export const editPayrollComponentOvertimes = (data) => {
  return axios.put(`/hr/payrollPayments/${data?.id}/overtimes`, data);
};

export const editShortcutPayrollComponentOvertime = (data) => {
  return axios.put(`/hr/payrollPayments/${data?.id}/overtimes/shortcut`, data);
};

export const resetPayrollComponentOvertime = (data) => {
  return axios.put(`/hr/payrollPayments/${data?.id}/overtimes/reset`);
};

export const updatePayrollPaymentStatus = (data) => {
  return axios.patch(`/hr/payrollPayments/${data.id}/status/${data?.statusId}`);
};

export const updatePayrollPaymentReadyToPay = (data) => {
  return axios.patch(`/hr/payrollPayments/readyToPay`, data);
};

export const updatePayrollPayment = (data) => {
  return axios.patch(`/hr/payrollPayments/${data?.id}/updateSlip`, data);
};

export const deletePayrollPaymentNonFixedSalarySlip = (id) => {
  return axios.delete(`/hr/payrollPayments/nonFixedSalarySlips/${id}`);
};

export const deleteAttachmentPayrollPayment = (data) => {
  return axios.delete(
    `/hr/payrollPayments/${data?.payrollPaymentSlipId}/attachment/delete/${data?.id}`
  );
};

export const uploadImportPayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/manual/${data?.id}/uploadImport`,
    data?.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const executeImportPayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/manual/${data?.id}/executeImport`,
    data?.data
  );
};

export const uploadImportNotePayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data?.id}/uploadImport`,
    data?.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const executeImportNotePayrollPayment = (data) => {
  return axios.post(
    `/hr/payrollPayments/${data?.id}/executeImport`,
    data?.data
  );
};
