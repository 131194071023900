import { useState, useRef, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Divider,
  message,
  Typography,
} from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getSavedEmail } from "utils/cookies";
import googleSvg from "assets/svg/google.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginModalSelector } from "redux/selectors";
import { hideLoginModal } from "redux/loginSlice";
import store from "store";
import { logout } from "redux/configSlice";

const { Text } = Typography;

const LoginForm = ({ onSubmit, onGoogleLogin, loading, email }) => {
  const { t } = useTranslation();
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginModal = useSelector(loginModalSelector);
  const [form] = Form.useForm();
  const [editEmail, setEditEmail] = useState(false);

  const savedEmail = email || getSavedEmail();
  const failureGoogleLoginHandler = useCallback(
    (e) => {
      message.error(e?.message || t("notification.login_google_failure"));
    },
    [t]
  );
  const login = useGoogleLogin({
    onSuccess: onGoogleLogin,
    onError: failureGoogleLoginHandler,
    flow: "auth-code",
  });

  const googleLoginHandler = useCallback(() => login(), [login]);

  const goToForgotPassword = useCallback(() => {
    if (loginModal?.show) {
      store.remove("app.endpoint");
      dispatch(logout());
      dispatch(hideLoginModal());
      navigate("/user/forgot", { redirect: true });
    }
    navigate("/user/forgot");
  }, [navigate, dispatch, loginModal]);

  const editHandler = useCallback(() => {
    setEditEmail(true);
    setTimeout(() => {
      if (emailRef?.current) {
        emailRef.current?.focus();
        if (savedEmail && !editEmail) {
          emailRef.current?.input?.setSelectionRange(
            0,
            form.getFieldValue("email").length
          );
        }
      }
    }, 1);
  }, [form, savedEmail, editEmail]);

  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      requiredMark={false}
      initialValues={{ email: savedEmail, remember: true }}
      onFinish={onSubmit}
      autoComplete="off"
      size="small"
    >
      <Form.Item
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.email !== currentValues.email
        }
        label={t("users.email")}
      >
        {({ getFieldValue }) => (
          <div>
            {savedEmail && !editEmail ? (
              <div>
                {getFieldValue("email") || savedEmail}
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  type="text"
                  onClick={editHandler}
                />
              </div>
            ) : null}
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("users.please_input_your_email_address"),
                },
                {
                  type: "email",
                  message: t("users.email_is_not_a_valid_email"),
                },
              ]}
              noStyle
            >
              <Input
                ref={emailRef}
                size="middle"
                type={savedEmail && !editEmail ? "hidden" : undefined}
                autoFocus
                onBlur={() => setEditEmail(false)}
              />
            </Form.Item>
          </div>
        )}
      </Form.Item>
      <Form.Item
        label={t("users.password")}
        name="password"
        rules={[
          { required: true, message: t("users.please_input_your_password") },
        ]}
      >
        <Input.Password autoFocus ref={passwordRef} size="middle" />
      </Form.Item>

      <div className="flex items-center justify-between">
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>{t("users.remember_me")}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Text
            className="text-link-primary hover:cursor-pointer"
            onClick={goToForgotPassword}
          >
            {t("users.forgot_password_")}
          </Text>
        </Form.Item>
      </div>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          disabled={!!loading}
          size="middle"
        >
          {t("users.login")}
        </Button>
        <Divider />
        <Button
          onClick={googleLoginHandler}
          className="flex border h-auto"
          disabled={loading}
          block
        >
          <div className="flex flex-row my-1 items-center justify-center w-full space-x-3">
            <img src={googleSvg} alt="Google Icon" width={18} />
            <Text>{t("users.login_with_google")}</Text>
          </div>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
