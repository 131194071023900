import axios from "utils/axios";
import { stringify } from "query-string";

export const getAnnualLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/annualLeaves/pagination?${query}`);
};

export const getAnnualLeavesRemainingQuota = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/leaves/annualLeaves/remainingQuota/pagination?${query}`
  );
};

export const getSpecialLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/specialLeaves/pagination?${query}`);
};

export const getSickLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/sickLeaves/pagination?${query}`);
};

export const getOtherLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/otherLeaves/pagination?${query}`);
};

export const getUnpaidLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/unpaidLeaves/pagination?${query}`);
};

export const getAnnualLeave = (id) => {
  return axios.get(`/hr/leaves/annualLeaves/${id}`);
};

export const getSpecialLeave = (id) => {
  return axios.get(`/hr/leaves/specialLeaves/${id}`);
};

export const getSickLeave = (id) => {
  return axios.get(`/hr/leaves/sickLeaves/${id}`);
};

export const getOtherLeave = (id) => {
  return axios.get(`/hr/leaves/otherLeaves/${id}`);
};

export const getUnpaidLeave = (id) => {
  return axios.get(`/hr/leaves/unpaidLeaves/${id}`);
};

export const getSpecialLeavesStats = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/specialLeaves/stats?${query}`);
};

export const getAnnualLeaveMovements = (id, params) => {
  const query = stringify(params);
  return axios.get(`/hr/leaves/annualLeaveMovements/${id}?${query}`);
};

export const getBalancedAnnualLeaveMovements = (id) => {
  return axios.get(`/hr/leaves/annualLeaveMovements/${id}/balanced`);
};

export const addAnnualLeave = (data) => {
  return axios.post("/hr/leaves/annualLeaves", data);
};

export const addSpecialLeave = (data) => {
  return axios.post("/hr/leaves/specialLeaves", data);
};

export const addSickLeave = (data) => {
  return axios.post("/hr/leaves/sickLeaves", data);
};

export const addOtherLeave = (data) => {
  return axios.post("/hr/leaves/otherLeaves", data);
};

export const addUnpaidLeave = (data) => {
  return axios.post("/hr/leaves/unpaidLeaves", data);
};

export const executeImportAnnualLeaveRemainingQuota = (data) => {
  return axios.post(
    "/hr/leaves/annualLeaves/remainingQuota/executeImport",
    data
  );
};

export const uploadImportAnnualLeaveRemainingQuota = (data) => {
  return axios.post(
    "/hr/leaves/annualLeaves/remainingQuota/uploadImport",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const editAnnualLeave = (data) => {
  return axios.put(`/hr/leaves/annualLeaves/${data.id}`, data);
};

export const editSpecialLeave = (data) => {
  return axios.put(`/hr/leaves/specialLeaves/${data.id}`, data);
};

export const editSickLeave = (data) => {
  return axios.put(`/hr/leaves/sickLeaves/${data.id}`, data);
};

export const editOtherLeave = (data) => {
  return axios.put(`/hr/leaves/otherLeaves/${data.id}`, data);
};

export const editUnpaidLeave = (data) => {
  return axios.put(`/hr/leaves/unpaidLeaves/${data.id}`, data);
};

export const editAnnualLeaveRemainingQuota = (data) => {
  return axios.patch(
    `/hr/leaves/annualLeaves/remainingQuota/update/${data.id}`,
    data
  );
};

export const restoreLeave = (id) => {
  return axios.patch(`/hr/leaves/${id}/restore`);
};

export const deleteAnnualLeave = (id) => {
  return axios.delete(`/hr/leaves/annualLeaves/${id}`);
};

export const deleteSpecialLeave = (id) => {
  return axios.delete(`/hr/leaves/specialLeaves/${id}`);
};

export const deleteSickLeave = (id) => {
  return axios.delete(`/hr/leaves/sickLeaves/${id}`);
};

export const deleteOtherLeave = (id) => {
  return axios.delete(`/hr/leaves/otherLeaves/${id}`);
};

export const deleteUnpaidLeave = (id) => {
  return axios.delete(`/hr/leaves/unpaidLeaves/${id}`);
};

export const deleteAttachmentLeave = (data) => {
  return axios.delete(
    `/hr/leaves/${data?.leaveId}/attachment/delete/${data?.id}`
  );
};
