import { memo } from "react";
import {
  MdOutlineHome,
  MdOutlineDashboard,
  MdOutlineSupervisedUserCircle,
  MdOutlineHistory,
  MdOutlineMonetizationOn,
  MdOutlineLaptop,
  MdOutlineBarChart,
  MdOutlineLibraryBooks,
  MdOutlineTimeline,
  MdDirectionsRun,
  MdChecklist,
  MdOutlineAirplanemodeActive,
  MdCurrencyExchange,
  MdOutlineQuestionMark,
} from "react-icons/md";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { FaBuilding } from "react-icons/fa";
import {
  BsGear,
  BsCheck2Circle,
  BsCalendarCheck,
  BsPersonCheck,
} from "react-icons/bs";
import { TbFileDollar, TbMapSearch } from "react-icons/tb";
import { useSettingSelector } from "hooks/useSelector";
import managementClientDark from "assets/svg/client.svg";
import managementClientWhite from "assets/svg/client_white.svg";

const IconMenuItem = memo(({ name, ...props }) => {
  const settings = useSettingSelector();

  switch (name) {
    case "home":
    case "dashboard":
      return <MdOutlineHome {...props} />;
    case "employee":
      return <MdOutlineSupervisedUserCircle {...props} />;
    case "attendance":
      return <MdOutlineHistory {...props} />;
    case "work_schedule":
      return <BsCalendarCheck {...props} />;
    case "presence":
      return <BsPersonCheck {...props} />;
    case "leaves":
      return <MdOutlineAirplanemodeActive {...props} />;
    case "payroll":
    case "payroll_payment":
      return <RiMoneyDollarBoxLine {...props} />;
    case "finance":
      return <MdOutlineMonetizationOn {...props} />;
    case "inventory":
      return <MdOutlineLaptop {...props} />;
    case "company":
      return <FaBuilding {...props} />;
    case "report":
      return <MdOutlineBarChart {...props} />;
    case "accounting":
      return <MdOutlineLibraryBooks {...props} />;
    case "approval":
      return <BsCheck2Circle {...props} />;
    case "timeline":
      return <MdOutlineTimeline {...props} />;
    case "settings":
      return <BsGear {...props} />;
    case "client_visit":
      return <MdDirectionsRun {...props} />;
    case "presence_approval":
      return <MdChecklist {...props} />;
    case "cash_receipt":
      return <TbFileDollar {...props} />;
    case "reimbursement":
      return <MdCurrencyExchange {...props} />;
    case "FAQ":
      return <MdOutlineQuestionMark {...props} />;
    case "location_tracking":
      return <TbMapSearch {...props} />;
    case "client":
      return (
        <img
          src={
            settings?.dark_mode ? managementClientWhite : managementClientDark
          }
          alt="management-client"
          {...props}
        />
      );
    default:
      return <MdOutlineDashboard {...props} />;
  }
});

export default IconMenuItem;
