import { Layout } from "antd";
// import { Link } from "react-router-dom";
// import Logo from "assets/images/logo.png";
import Footer from "./Footer";

const { Content } = Layout;

const PublicLayout = ({ children, inverse, ...props }) => {
  return (
    <Layout className="h-screen" {...props}>
      {/* <Header className="bg-white flex items-center justify-between px-5 lg:px-10 shadow-md">
      <img width={117} src={Logo} alt="Logo" />
      <div className="flex flex-row gap-5 text-lg text-primary font-bold">
        <Link className="hover:border-b hover:text-opacity-50 hover:border-primary" to="https://gajihub.com">Login</Link>
        <Link className="hover:border-b hover:text-opacity-50 hover:border-primary" to="https://gajihub.com">Support</Link>
      </div>
      </Header> */}
      <Content
        className="flex items-center justify-center flex-col"
        // style={{
        //   backgroundImage: `url('/resources/images/bg-login.jpeg')`,
        //   backgroundSize: 'cover'
        // }}
      >
        {children}
      </Content>
      <Footer inverse={inverse} />
    </Layout>
  );
};

export default PublicLayout;
