import axios from "utils/axios";
import { stringify } from "query-string";

export const getCashReceiptBalance = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/cashReceipt/balance/pagination?${query}`);
};

export const getCashReceiptHistory = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/cashReceipt/history/pagination?${query}`);
};

export const getCashReceiptPayment = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/cashReceipt/payment/pagination?${query}`);
};

export const getCashReceiptEmployee = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/cashReceipt/employee/${params?.id}/pagination?${query}`
  );
};

export const getCashReceipt = (id) => {
  return axios.get(`/hr/cashReceipt/${id}`);
};

export const addCashReceipt = (data) => {
  return axios.post("/hr/cashReceipt", data);
};

export const addCashReceiptPayment = (data) => {
  return axios.post("/hr/cashReceiptPayment", data);
};

export const uploadImportCashReceipt = (data) => {
  return axios.post("/hr/cashReceipt/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportCashReceipt = (data) => {
  return axios.post("/hr/cashReceipt/executeImport", data);
};

export const uploadImportPlafonCashReceipt = (data) => {
  return axios.post("/hr/cashReceipt/plafon/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportPlafonCashReceipt = (data) => {
  return axios.post("/hr/cashReceipt/plafon/executeImport", data);
};

export const editCashReceipt = (data) => {
  return axios.patch(`/hr/cashReceipt/${data?.id}`, data);
};

export const paidCashReceipt = (data) => {
  return axios.patch(`/hr/cashReceipt/${data?.id}/paid`, data);
};

export const deleteCashReceipt = (id) => {
  return axios.delete(`/hr/cashReceipt/${id}`);
};

export const deleteCashReceiptPayment = (id) => {
  return axios.delete(`/hr/cashReceiptPayment/${id}`);
};

export const deleteAttachmentCashReceipt = (data) => {
  return axios.delete(
    `/hr/cashReceipt/${data?.cashReceiptId}/attachment/delete/${data?.id}`
  );
};
