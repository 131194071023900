import { useMutation, useQueryClient, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import store from "store";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  updateProfile,
  changePassword,
  singleLogin,
  register,
  requestRecovery,
  verifyToken,
  reset,
  changeLanguage,
  verifyGoogleToken,
  logout,
  revokeLogin,
} from "apis/user";
import { message } from "antd";
import {
  setConfig,
  setApp,
  setUser,
  logout as reduxLogout,
  setMenu,
  setOption,
} from "redux/configSlice";
import { setReference } from "redux/referenceSlice";
import { resetLoginState } from "redux/loginSlice";
import { useConfigSelector } from "hooks/useSelector";
import { setToken, setSavedEmail, getToken, removeToken } from "utils/cookies";
import { findHrCompanies, generateBaseUri } from "utils/helper";
import Axios from "utils/axios";

export const useUpdateProfile = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation((payload) => updateProfile(payload), {
    onMutate: async (payload) => {
      try {
        await queryClient.cancelQueries("inits");
        const previousInits = queryClient.getQueryData("inits");
        queryClient.setQueryData("inits", (old) => {
          if (old?.user) {
            const user = { ...old.user };
            old.user = { ...user, ...payload };
            return { ...old };
          }
          return old;
        });

        return { previousInits };
      } catch (error) {}
    },
    onSuccess: (response) => {
      if (response.data.success) {
        dispatch(setUser({ ...response.data.data }));
        message.success(response.data?.message);
      } else {
        message.error(response.data?.message);
      }
    },
    onError: (_err, tax, context) => {
      queryClient.setQueryData("inits", context.previousInits);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: () => {
      queryClient.invalidateQueries("inits");
    },
  });
};

export const useRevokeLogin = () => {
  const { t } = useTranslation();
  return useMutation(() => revokeLogin(), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data?.message);
      } else {
        message.error(response.data?.message);
      }
    },
    onError: (error) =>
      message.error(t("'profile.cannot_logout_from_all_device'")),
  });
};

export const useChangePassword = () => {
  const { t } = useTranslation();
  return useMutation((payload) => changePassword(payload), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data?.message);
      } else {
        message.error(response.data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { apiToken } = useConfigSelector();
  const payload = apiToken ? apiToken : getToken() ? getToken() : null;
  return useMutation(() => logout(payload), {
    onMutate: () => {
      // Dihapus dulu previous path dan selected endpoinnya
      store.remove("app.endpoint");
      store.remove("app.previousPathname");
      Axios.defaults.baseURL = generateBaseUri();
      // Dihapus tokennya
      removeToken();
      // Reset reduxnya
      dispatch(reduxLogout());
      navigate("/user/login", { replace: true });
    },
    onSuccess: (response) => {
      if (!response.data.success) {
        message.error(response.data.message);
      }
    },
  });
};

const defaultCustomOptions = {
  withSetEndpoint: false,
  withSetEmail: true,
};

export const useSingleLogin = (customOptions = {}) => {
  customOptions = { ...defaultCustomOptions, ...customOptions };
  // const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(({ email, password }) => singleLogin(email, password, 1), {
    onSuccess: (response) => {
      if (response?.data.success) {
        successLogin(response.data?.data, dispatch, customOptions, queryClient);
      }
    },
    // onError: (_err) => {
    //   message.error(
    //     !_.isEmpty(_err) && _.isString(_err)
    //       ? _err
    //       : t("error.an_error_happened")
    //   );
    // },
  });
};

export const useVerifyGoogleToken = (customOptions = {}) => {
  customOptions = { ...defaultCustomOptions, ...customOptions };
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(({ code }) => verifyGoogleToken(code), {
    // onMutate: () => {
    //   dispatch(
    //     showLoader({
    //       message: 'Please wait, verifiying token',
    //     }),
    //   );
    // },
    onSuccess: (response) => {
      if (response?.data.success) {
        successLogin(response.data?.data, dispatch, customOptions, queryClient);
      } else {
        message.error(response.data?.message || t("error.an_error_happened"));
      }
    },
    onError: (e) => {
      message.error(e.data?.message || t("error.an_error_happened"));
    },
    // onSettled: () => dispatch(hideLoader()),
  });
};

// Callback untuk login sukses
export const successLogin = (
  { init, data, companies, user },
  dispatch,
  customOptions = {},
  queryClient
) => {
  customOptions = { ...defaultCustomOptions, ...customOptions };
  const config = {
    apiToken: data?.access_token,
    expiredToken: data?.expires_at,
    // Jika single company (ada init) diset dari init, jika tidak diambil di user
    locale: init ? init?.user?.lang : user?.lang || "id-ID",
  };
  setToken(data.access_token);
  if (customOptions.withSetEmail) {
    setSavedEmail(user.email);
  }
  const hrCompanies = findHrCompanies(companies);
  // Set email
  if (hrCompanies.length === 1) {
    // Find selected company object
    const findCompany = companies.find(
      (row) => row.website_id.toString() === init.website_id.toString()
    );
    if (findCompany) {
      config.endpoint = findCompany?.endpoint;
      config.selectedCompany = findCompany;
      if (customOptions.withSetEndpoint) {
        store.set("app.endpoint", config.endpoint);
        Axios.defaults.baseURL = generateBaseUri();
        queryClient.clear();
      }
    }
  } else {
    store.remove("app.endpoint");
  }
  // Set config untuk di simpan di redux
  dispatch(setConfig(config));
  let app = { user, companies };
  if (init) {
    if (init.setting) {
      app.setting = init.setting;
    }
    if (init.user) {
      app.user = init.user;
    }
    if (init.option) {
      dispatch(setOption(init.option));
    }
    if (init.reference) {
      dispatch(setReference(init.reference));
    }

    if (init.user?.menus) {
      dispatch(setMenu(init.user?.menus));
    }

    app = {
      ...app,
      lang: init.lang,
      billing: init.billing,
      translation: init.translation,
      website_id: parseInt(init.website_id, 10),
      setting: init.setting,
      dashboard: init.dashboard,
      loadedInit: true,
      gajihubStartDate: init?.gajihub_start_date,
      employeeCountAvailable: init?.employee_count_available || [],
      defaultMinEmployeeOrder: init?.default_min_employee_order || 0,
      maxCompanies: init?.max_companies || 0,
      showNps: init?.show_nps || 0,
    };
  }
  // Jika belum punya perusahaan
  if (hrCompanies.length <= 1) {
    app.loadedInit = true;
  } else {
    if (!customOptions.withSetEndpoint) {
      app.loadedInit = false;
    }
  }
  dispatch(setApp(app));
  dispatch(resetLoginState());
};

export const useRegister = () => {
  const { t } = useTranslation();
  return useMutation((payload) => register(payload), {
    onError: (_err) => {
      message.error(
        !_.isEmpty(_err) && _.isString(_err)
          ? _err
          : t("error.an_error_happened")
      );
    },
  });
};

export const useRequestRecovery = () => {
  const { t } = useTranslation();
  return useMutation((payload) => requestRecovery(payload), {
    onError: (_err) => {
      message.error(
        !_.isEmpty(_err) && _.isString(_err)
          ? _err
          : t("error.an_error_happened")
      );
    },
  });
};

export function useVerifyToken(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["verifyToken/resetPassword"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await verifyToken(payload.token);
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(error.message || t("error.an_error_happened")),
      ...options,
    }
  );
}

export const useResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return useMutation((payload) => reset(payload), {
    onSuccess: (response) => {
      if (response.data.success) {
        successLogin(response.data?.data, dispatch, {}, queryClient);
      }
    },
    onError: (_err) => {
      message.error(
        !_.isEmpty(_err) && _.isString(_err)
          ? _err
          : t("error.an_error_happened")
      );
    },
  });
};

export const useChangeLanguage = () => {
  const { t } = useTranslation();
  // const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation(({ locale }) => changeLanguage(locale), {
    // onMutate: () => {
    //   dispatch(
    //     showLoader({
    //       title: t('title.change_language'),
    //       message: t('message.changing_language'),
    //     }),
    //   );
    // },
    onSuccess: (response, { locale }) => {
      if (response?.data.success) {
        // queryClient.setQueryData('inits', () => response.data?.data?.init);
        store.set("app.settings.locale", locale);
        dispatch(setConfig({ locale }));
        dispatch(
          setApp({
            ...response.data?.data?.init,
            website_id: parseInt(response.data?.data?.init?.website_id, 10),
          })
        );
        dispatch(setMenu({ ...response.data?.data?.init?.user?.menus }));
        dispatch(setReference({ ...response.data?.data?.init?.reference }));
        message.success(response.data?.message);
      }
      // dispatch(hideLoader());
    },
    onError: (_err) => {
      message.error(
        !_.isEmpty(_err) && _.isString(_err)
          ? _err
          : t("error.an_error_happened")
      );
      // dispatch(hideLoader());
    },
  });
};
