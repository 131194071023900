import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";

import { message } from "antd";
import * as api from "apis/jobPosition";

//Query untuk get all job positions
export function useJobPositions({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobPositions", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobPositions(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail job positions
export function useJobPosition({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobPosition", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobPosition(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get all job positions tree
export function useJobPositionsTree(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobPositions/tree", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobPositionsTree(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get parents job positions
export function useJobPositionParents({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobPosition/parents", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobPositionParents(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get all job position employee
export function useJobPositionEmployee(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["jobPosition/employee", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getJobPositionEmployee(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

// Query untuk add job postion
export const useAddJobPosition = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addJobPosition(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit job postion
export const useEditJobPosition = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editJobPosition(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete job postion
export const useDeleteJobPosition = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deleteJobPosition(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk archive job position
export const useArchiveJobPosition = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.archiveJobPosition(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

//Query untuk unarchive job position
export const useUnarchiveJobPosition = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.unarchiveJobPosition(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
