import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { parse } from "query-string";
import store from "store";
import { setToken, removeToken } from "utils/cookies";
import { generateBaseUri } from "utils/helper";
import Axios from "utils/axios";
import MainLayout from "./Main";
import LoginLayout from "./Login";
import SelectCompanyLayout from "./SelectCompany";
import NoCompanyLayout from "./Main/NoCompany";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { selectedCompanySelector } from "redux/selectors";
import { trackingPage } from "utils/gtm";

const Layouts = {
  login: LoginLayout,
  main: MainLayout,
  selectCompany: SelectCompanyLayout,
  noCompanyLayout: NoCompanyLayout,
};

const pathnameNoCompanyLayout = ["/companies/add", "/only-employee-role"];

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchs = parse(searchParams.toString());
  const selectCompany = useSelector(selectedCompanySelector);

  useEffect(() => {
    // if there is token params, set token
    if (searchs.token) {
      store.remove("app.previousPathname");
      store.remove("app.endpoint");
      removeToken();
      setToken(searchs.token);
      // if there is endpoint params, set endpoint
      if (searchs.endpoint && !selectCompany) {
        store.set("app.endpoint", searchs.endpoint);
        Axios.defaults.baseURL = generateBaseUri();
        queryClient.clear();

        // onSelectCompany(
        //   {
        //     website_id: searchs.website_id,
        //     endpoint: searchs.endpoint,
        //   },
        //   "/dashboard"
        // );
      }
      setSearchParams({});
      //Hilangkan search params token
      // history.replaceState(null, null, `/dashboard`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectCompany]);

  // Tracking page
  useEffect(() => {
    trackingPage(`${pathname}${search}`);
  }, [pathname, search]);

  const getLayout = () => {
    // if (pathname === "/") {
    //   return "public";
    // }
    if (pathname === "/user/select-company") {
      return "selectCompany";
    }
    if (/^\/user(?=\/|$)/i.test(pathname)) {
      return "login";
    }

    if (pathnameNoCompanyLayout.includes(pathname) && !!searchs.from_login) {
      return "noCompanyLayout";
    }
    return "main";
  };

  const layout = getLayout();
  const Container = Layouts[layout];

  return (
    <>
      <Helmet titleTemplate="GajiHub | %s" title="GajiHub Software" />
      <Container>{children}</Container>
    </>
  );
};

export default Layout;
