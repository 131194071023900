import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import * as api from "apis/payroll";

//Query untuk get all payroll salary slips
export function usePayrollSalarySlips() {
  const { t } = useTranslation();
  return useQuery(
    ["payroll/salarySlips"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollSalarySlips();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get detail payroll
export function usePayroll({ payload = {} }, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["payroll", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayroll(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

//Query untuk get detail payroll
export function usePayrollsEmployee({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["payrolls/employee", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollsEmployee(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

//Query untuk get payroll effective date
export function usePayrollsEmployeeEffectiveDate({ payload = {} }) {
  const { t } = useTranslation();
  return useQuery(
    ["payrolls/employee/effectiveDate", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollsEmployeeEffectiveDate(payload.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
    }
  );
}

// Query untuk add payroll
export const useAddPayroll = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addPayroll(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk edit payroll
export const useEditPayroll = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editPayroll(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query untuk delete payroll
export const useDeletePayroll = () => {
  const { t } = useTranslation();
  return useMutation((id) => api.deletePayroll(id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import payroll
export const useUploadImportPayroll = () => {
  return useMutation((payload) => api.uploadImportPayroll(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import payroll
export const useExecuteImportPayroll = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportPayroll(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

// Query upload import payroll horizontal
export const useUploadImportPayrollHorizontal = () => {
  return useMutation((payload) => api.uploadImportPayrollHorizontal(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => {},
  });
};

// Query execute import payroll horizontal
export const useExecuteImportPayrollHorizontal = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.executeImportPayrollHorizontal(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
