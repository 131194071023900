import { useEmployee } from "queries";
import { useMemo } from "react";
import { useQueryClient } from "react-query";

const EmployeeBreadcrumb = ({ match }) => {
  const id = match.params?.id;
  const queryClient = useQueryClient();
  const employee = queryClient.getQueryData(["employee", { id }]);

  const { data } = useEmployee({ payload: { id } }, { enabled: !employee });

  const dataEmployee = useMemo(() => {
    if (!employee || !data) {
      return;
    }
    return employee ? employee : data;
  }, [employee, data]);

  return <span>{dataEmployee?.name || ""}</span>;
};

export default EmployeeBreadcrumb;
