import axios from "utils/axios";

export const getPayrollSalarySlips = () => {
  return axios.get("/hr/payrolls/salarySlips");
};

export const getPayroll = (id) => {
  return axios.get(`/hr/payrolls/${id}`);
};

export const getPayrollsEmployee = (id) => {
  return axios.get(`/hr/payrolls/employees/${id}`);
};

export const getPayrollsEmployeeEffectiveDate = (id) => {
  return axios.get(`/hr/payrolls/new/${id}`);
};

export const addPayroll = (data) => {
  return axios.post("/hr/payrolls", data);
};

export const editPayroll = (data) => {
  return axios.put(`/hr/payrolls/${data.id}`, data);
};

export const deletePayroll = (id) => {
  return axios.delete(`/hr/payrolls/${id}`);
};

export const uploadImportPayroll = (data) => {
  return axios.post("/hr/payrolls/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportPayroll = (data) => {
  return axios.post("/hr/payrolls/executeImport", data);
};

export const uploadImportPayrollHorizontal = (data) => {
  return axios.post("/hr/payrolls/horizontal/uploadImport", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const executeImportPayrollHorizontal = (data) => {
  return axios.post("/hr/payrolls/horizontal/executeImport", data);
};
