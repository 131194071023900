import { startCase, toLower, isEmpty } from "lodash";
import store from "store";
import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "utils/helper";
import { appMessage } from "utils/formatting";
import { urlMenu } from "utils/urlMenu";

const initialState = {
  config: {
    apiToken: null,
    expiredToken: null,
    locale: "id-ID",
    selectedCompany: null,
    endpoint: "",
    isMobileView: false,
    isTabView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: store.get("app.menu.isMenuCollapsed") || false,
  },
  app: {
    user: {},
    lang: [],
    companies: [],
    translation: {},
    billing: {},
    website_id: null,
    loadedInit: false,
    gajihubStartDate: null,
    menu: [],
    option: {},
    loadingInit: false,
    setting: {
      dark_mode: store.get("app.darkMode")
        ? parseInt(store.get("app.darkMode"))
        : 0,
      theme_color_value: store.get("app.themeColorValue") || "#E53F6A",
      app_timezone: store.get("app.timeZone") || "",
    },
    dashboard: [],
    employeeCountAvailable: [],
    defaultMinEmployeeOrder: 0,
    maxCompanies: 0,
    showNps: 0,
  },
};

//Memastikan format title huruf depan besar
const formattedTitle = (title) => startCase(toLower(title));

//Format Menu Dashboard
const formattedDashboardMenu = (dashboardMenus) => {
  let menus = [];
  menus = dashboardMenus.map((item, itemIndex) => {
    let subMenu = {
      key: `${item.title}-${itemIndex}`,
      title: item.title,
      name: item.name,
      icon: item.icon,
    };

    if (item.divider) {
      subMenu.divider = item.divider;
    }

    if (item.sub) {
      subMenu["sub"] = item.sub.map((row, rowIndex) => {
        const subChildrenMenu = {
          key: `${row.title}-${itemIndex}-${rowIndex}`,
          title: formattedTitle(row.title),
          name: row.name,
          url: urlMenu(row.url),
          icon: row.icon,
        };

        if (row.divider) {
          subChildrenMenu.divider = row.divider;
        }

        return subChildrenMenu;
      });
    } else {
      subMenu["url"] = urlMenu(item.url);
      // subMenu["url"] = `/${item.icon}`;
    }

    return subMenu;
  });

  return menus;
};

//Format setting menu
const formattedSettingMenu = (settingMenus) => {
  let menus = [];
  menus = settingMenus.map((item, itemIndex) => {
    let subMenu = {
      key: `${item.name}-${itemIndex}`,
      title: item.title,
      name: item.name,
      icon: item.icon,
      group_id: item.group_id,
    };

    if (item.divider) {
      subMenu.divider = item.divider;
    }

    if (item.children) {
      subMenu["children"] = item.children.map((row, rowIndex) => {
        const subChildrenMenu = {
          key: `${row.name}-${rowIndex}`,
          title: formattedTitle(row.title),
          name: row.name,
          url: `${row.url}`,
          icon: row.icon,
          group_id: row.group_id,
        };

        if (row.divider) {
          subChildrenMenu.divider = row.divider;
        }

        return subChildrenMenu;
      });
    } else {
      if (!subMenu.divider) {
        subMenu["url"] = `${item.url}`;
      }
    }

    return subMenu;
  });

  return menus;
};

//Ubah struktur menu dari api sesuai kebutuhan client
const formattedMenu = (menus, app) => {
  let menuLeftData = [];
  const dashboard = formattedDashboardMenu(menus?.dashboard || []);
  menuLeftData = [...dashboard];
  const menuSetting = {
    title: appMessage("settings"),
    name: "settings",
    key: "Settings",
    icon: "cogs",
    children: formattedSettingMenu(menus?.settings || []),
    url: "/settings",
  };
  //condition jika user login bukan hanya memiliki role karyawan
  if (!app?.user?.is_user_has_only_employee_role_on_web) {
    menuLeftData.push(menuSetting);

    if (!isEmpty(menus?.faq)) {
      menuLeftData.push({ ...menus?.faq });
    }
  }

  return menuLeftData;
};

const slice = createSlice({
  name: "module",
  initialState,
  reducers: {
    setConfig: (state, { payload }) =>
      updateObject(state, { config: { ...state.config, ...payload } }),
    setApp: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          ...payload,
        },
      }),
    resetData: (state) =>
      updateObject(state, {
        config: {
          ...initialState.config,
          apiToken: state.config.apiToken,
          expiredToken: state.config.expiredToken,
          isMobileView: state.config.isMobileView,
          isTabView: state.config.isTabView,
        },
        app: initialState.app,
      }),
    logout: (state) =>
      updateObject(state, {
        config: initialState.config,
        app: initialState.app,
      }),
    setUser: (state, { payload }) =>
      updateObject(state, {
        app: { ...state.app, user: { ...state.app.user, ...payload } },
      }),
    setMenu: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          menu: formattedMenu(payload, state.app),
        },
      }),
    setOption: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          option: { ...state.app.option, ...payload },
        },
      }),
    setSetting: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          setting: { ...state.app.setting, ...payload },
        },
      }),
    setDashboard: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          dashboard: payload,
        },
      }),
    setLoadingInit: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          loadingInit: payload,
        },
      }),
    setLoadedInit: (state, { payload }) =>
      updateObject(state, {
        app: {
          ...state.app,
          loadedInit: payload,
        },
      }),
  },
});

export const {
  setConfig,
  logout,
  setUser,
  setApp,
  resetData,
  setMenu,
  setOption,
  setLoadedInit,
  setLoadingInit,
  setSetting,
  setDashboard,
} = slice.actions;

export default slice;
