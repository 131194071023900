import { memo } from "react";
import { Link as LinkRouter } from "react-router-dom";

const Link = ({ to, ...props }) => {
  if (!to) {
    return <span {...props}>{props?.children}</span>;
  }
  return (
    <LinkRouter
      className="text-primary hover:opacity-80 hover:underline hover:underline-offset-2 hover:decoration-solid"
      // className="text-link-primary"
      to={to}
      {...props}
    >
      {props?.children}
    </LinkRouter>
  );
};

export default memo(Link);
